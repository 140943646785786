import store from '../store';
export async function auth (): Promise<void> {
	await store.dispatch("auth");
}

export function hasToken (): boolean {
	return typeof store.getters.USER_TOKEN == 'string';
}

export function isAdmin (): boolean {
	return store.getters.isAdmin
}