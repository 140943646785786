import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
import { rejoin, split } from './token-hash';
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: 'aes' });
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    TYST: null,
    TYSP: null,
    TYSD: null,
    TYUT: [0,0,0], //user, developer, admin
    isAdmin: false,
    isUser: false,
    theme: 'light',
    loading: false,
    isDeveloper: false,
    personal_instance: null,
    up_p: false,
    payment_redirect_url: null,
    payment_redirect_url_date: null,
  },
  mutations: {
    setUserToken (state, token) {
      [state.TYST, state.TYSP, state.TYSD] = split(token, 3);
    },
    removeUserToken (state) {
      state.TYST = null;
      state.TYSP = null;
      state.TYSD = null;
    },
    resetState (state) {
      state.TYST = null;
      state.TYSP = null;
      state.TYSD = null;
    },
    setLoading(state, bool) {
      state.loading = bool;
    },
    setPersonalInstance(state, id) {
      state.personal_instance = id;
    },
    setRoles (state, {role, is_developer}) {
      state.TYUT[2] = role=='admin'?1:0;
      state.TYUT[0] = role=='user'?1:0;
      state.TYUT[1] = is_developer;
    },
  },
  getters: {
    username: async (state, getters) => {
      if (!getters.USER_TOKEN) return 'ورود';
      let tmp = 'حساب کاربری';
      const user = await getters.USER;
      if (user.name) tmp = user.name;
      else tmp = user.mobile;
      return tmp;
    },

    isAdmin: (state, getters) => {
      return (getters.USER_TOKEN !== null && state.TYUT[2]);
    },
    USER_TOKEN: (state) => {
      return rejoin([state.TYST, state.TYSP, state.TYSD]);
    },
    USER: async ( state, getters ) => {
      if (!getters.USER_TOKEN) return null;
      try {
        const res = await axios
        .get(
          `${process.env.VUE_APP_baseURL}/user/me`,
            {
              headers: {
                Authorization: `Bearer ${getters.USER_TOKEN}`
              }
            }
        );
        // if (!res.data.id) {
        //   this.commit('removeUserToken')
        // }
        return res.data;
      } catch {
        // this.commit('removeUserToken')
        return null;  
      }
    },
    getLoading: state => {
      return state.loading;
    },
    personal_instance: state => {
      return state.personal_instance;
    },
    update_inventory: state => {
      return state.up_p;
    },
  },
  actions: {
    async auth (context) {
      if (!context.getters.USER_TOKEN) return false;
      try {
        const res = await axios
        .get(
          `${process.env.VUE_APP_baseURL}/user/me`,
            {
              headers: {
                Authorization: `Bearer ${context.getters.USER_TOKEN}`
              }
            }
        );
        if (!res.data.id) {
          context.commit('removeUserToken')
          return false;
        } else {
          return true; 
        }
      } catch {
        context.commit('removeUserToken')
        return false;  
      }
    },
    exit({commit}) {
      commit('resetState')
      
    }
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => {
        try {
          return ls.get(key)
        } catch {
          ls.remove(key);
          return null
        }
      },
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    key: '_state',
  })]
})
