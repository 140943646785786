import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import { isAdmin } from './auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },{
    path: '/dashboard',
    beforeEnter : async (to, from, next) => {
        if (isAdmin()){
          next();
        } else {
          next('/login');
        }
    },
    component: Dashboard,
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Dashboard/Users.vue'),
      },{
        path: '/instances',
        name: 'instances',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Dashboard/Instances.vue'),
      }, {
        path: 'support',
        name: 'support',
        component: () => import(/* webpackChunkName: "user-support" */ '../views/Dashboard/DashboardSupport.vue')
      }, {
        path: 'support/ticket/:ticket_id',
        name: 'support-ticket',
        component: () => import(/* webpackChunkName: "support-ticket" */ '../views/Dashboard/SupportTicket.vue'),
      }, {
        path: 'withdrawals',
        name: 'withdrawals',
        component: () => import(/* webpackChunkName: "Withdrawals" */ '../views/Dashboard/Withdrawals.vue'),
      }, {
        path: 'deposits',
        name: 'deposits',
        component: () => import(/* webpackChunkName: "Deposits" */ '../views/Dashboard/Deposits.vue'),
      },
    ]
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/admin',
  routes
})

export default router
