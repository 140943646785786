import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'tayeh-ui/dist/index.min.css'
import TayehUi from 'tayeh-ui/dist/index.es.min.js';
import '@/assets/styles/shared.scss';
import '@/assets/styles/bootstrap.css';
import { format } from 'date-fns-jalali'

const date = function (value: string|Date, date_format?: string) {
	return value?typeof value=='string'?format(new Date(value), date_format||'yy-MM-dd'):format(value, date_format||'yy-MM-dd'):'';
}

Vue.config.productionTip = false
Vue.use(TayehUi)
Vue.use(TayehUi.currency)
Vue.filter('date', date);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
