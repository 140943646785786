<template>
  <ty-container>
      <ty-aside width="80px" class="pt-4 px-3 aside">
          <div class="ty-flex ty-flex-col ty-gap-4">
              <router-link :to="{name: 'users'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-user"/>
              </router-link>
              <router-link :to="{name: 'instances'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-shop"/>
              </router-link>
              <router-link :to="{name: 'withdrawals'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-minus"/>
              </router-link>
              <router-link :to="{name: 'deposits'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-plus"/>
              </router-link>
              <router-link :to="{name: 'sms'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-speaker-outline"/>
              </router-link>
              <router-link :to="{name: 'support'}">
                <ty-button type="clear" class="text-right" color="dark" icon="ty-icon-message"/>
              </router-link>
          <!-- <ty-button type="clear" class="text-right">
              کاربران مدیریت
          </ty-button> -->
          </div>
      </ty-aside>
      <ty-main>
          <router-view/>
      </ty-main>
  </ty-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.aside {
    overflow: visible;
    height: 100vh;
}
</style>