





















import { Component, Vue } from 'vue-property-decorator';
import { Cirus } from '@/classes/Http'
@Component
export default class Login extends Vue {
    username: string = null;
    password: string = null;
    async login () {
        try {
            const res = await Cirus.login(this.username, this.password);
            if (typeof res.data.access_token === 'undefined' || !res.data.access_token)
                alert("لطفا رمز را صحیح وارد کنید.");
            else {
                this.$store.commit("setUserToken", res.data.access_token);
                this.$store.commit("setRoles", res.data);
                // this.$router.push("/");
                window.location.href = "/admin/dashboard";
            }
        } catch {
            alert('لطفا نام کاربری و رمز عبور را صحیح وارد کنید.')
        }
    }
}
