import axios from 'axios';
import store from '@/store';
const Errors: any = {
	400: "مشکلی در درخواست شما وجود دارد.",
	401: "لطفا ابتدا وارد حساب کاربری خود شوید.",
	402: "دسترسی به این اطلاعات تنها برای کاربران با حساب طلایی امکان پذیر است.",
	403: "شما دسترسی لازم را ندارید",
	500: "مشکلی در سمت سرور رخ داده است. لطفا دوباره تلاش کنید.",
	unknown: 'لطفا دوباره تلاش کنید.'
}

const Cirus = {
		AUTH_HEADER( config= {}) {
				return {
					headers: {
					Authorization: `Bearer ${store.getters.USER_TOKEN}`,
				}, ...config}			
		},
		async post(uri: string, body = {}, server=process.env.VUE_APP_baseURL) {
			try {
				const res = await axios.post(
					`${server}${uri}`,
					body,
					this.AUTH_HEADER()
				);
				return res;
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
				throw err;
			}
		},
		async put(uri: string, body= {}, server=process.env.VUE_APP_baseURL) {
			try {
				const res = await axios.put(
					`${server}${uri}`,
					body,
					this.AUTH_HEADER()
				)
				return res;
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
				throw err;
			}
		},
		async get(uri: string, config= {}, server=process.env.VUE_APP_baseURL) {
			try {
				const res = await axios.get(
					`${server}${uri}`,
					this.AUTH_HEADER(config)
				);
				return res;
			} catch (err: any) {				
				alert(Errors[err.response.status]??Errors.unknown)
				throw err;
			}
		},
		async delete(uri: string, server=process.env.VUE_APP_baseURL) {
			try {
				const res = await axios.delete(
					`${server}${uri}`,
					this.AUTH_HEADER())
					return res;
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
				throw err;
			}
		},
		login (username: string, password: string) {
			return axios.post(
				`${process.env.VUE_APP_authURL}/user/login`,
				{username, password}
			)
		},
		resetPassword (mobile: string) {
			return axios.post(
				`${process.env.VUE_APP_authURL}/user/send-mobile-verification`,
				{mobile}
			)
		},
		verifyPassword(mobile: string, verification_code: string) {
			return axios.post(
				`${process.env.VUE_APP_authURL}/user/verify-mobile`,
				{mobile, verification_code}
			)
		},
		upload_image(file: any) {
			try {
				const formData = new FormData()
				formData.append('image', file)
				return axios.post(
					`${process.env.VUE_APP_blobURL}/image`,
					formData,
					{
						headers: {
							Authorization: 'Bearer ' + store.getters.USER_TOKEN
						}
					}
				)
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
			}
		},

		upload_avatar(file: any) {
			try {
				const formData = new FormData()
				formData.append('image', file)
				return axios.post(
					`${process.env.VUE_APP_blobURL}/avatar`,
					formData,
					{
						headers: {
							Authorization: 'Bearer ' + store.getters.USER_TOKEN
						}
					}
				)
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
			}
		},

		upload_banner(file: any) {
			try {
				const formData = new FormData()
				formData.append('image', file)
				return axios.post(
					`${process.env.VUE_APP_blobURL}/banner`,
					formData,
					{
						headers: {
							Authorization: 'Bearer ' + store.getters.USER_TOKEN
						}
					}
				)
			} catch (err: any) {
				alert(Errors[err.response.status]??Errors.unknown)
			}
		},
}


export {
	Cirus
};